<template>
  <div class="ui-product">
    <header class="ui-product__header">
      <CRow class="justify-content-between align-items-center h-100">
        <CCol sm="auto" class="mt-2 mb-1 my-sm-0">
          <div class="ui-type-display-lg">Crea nuovo prodotto</div>
        </CCol>
        <CCol sm="auto" class="mt-1 mb-2 my-sm-0">
          <CButton color="outline-primary" class="mr-3">ANNULLA</CButton>
          <CButton color="primary">SALVA</CButton>
        </CCol>
      </CRow>
    </header>
    <section class="ui-product__body">
      <aside class="ui-product__nav">
        <ul>
          <li><a class="active" href="#0">Descrizione</a></li>
          <li><a href="#0">Logistica</a></li>
          <li><a href="#0">Certificazioni</a></li>
          <li><a href="#0">Varianti</a></li>
          <li><a href="#0">Prezzo e peso</a></li>
          <li><a href="#0">Origine e deperibilità</a></li>
          <li><a href="#0">Foto</a></li>
          <li><a href="#0">Disponibilità</a></li>
        </ul>
      </aside>

      <main class="ui-product__main">
        <CForm style="padding: 0 5px">
          <CSelect
            label="Lingua"
            :options="['Italiano', 'Inglese', 'Francese', 'Tedesco']"
            :horizontal="{ label: 'col-sm-auto', input: 'col-sm-auto' }"
            custom
          />
        </CForm>

        <CCard class="card-bordered">
          <CCardHeader>
            <div class="card-title">Descrizione del prodotto</div>
            <div class="card-subtitle">
              Inserisci le informazioni principali che saranno visibili al
              cliente finale
            </div>
          </CCardHeader>
          <CCardBody>
            <div class="form-group">
              <label>Tipologia</label>
              <CInputRadioGroup
                :options="[
                  'Alimentare',
                  'Non alimentare',
                  'Digitale',
                  'Servizi',
                ]"
                custom
                inline
              />
            </div>
            <CRow>
              <CCol md="8">
                <CInput
                  label="Titolo"
                  required
                  placeholder="Inserisci il nome del tuo prodotto"
                  description="lorem"
                />
              </CCol>
            </CRow>
            <div class="card-heading">Heading separator</div>
            <CRow>
              <CCol md="8">
                <CInput required>
                  <template #label>
                    <label>
                      Pippo
                      <em class="text-danger">*</em>
                      <span
                        class="text-primary ml-1"
                        v-c-tooltip="{
                          placement: 'right',
                          content:
                            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia exercitationem expedita nobis fugiat ea illum praesentium, laborum magnam beatae nostrum recusandae maxime reprehenderit, quam. Sunt molestiae, doloremque similique. Asperiores, dignissimos?',
                        }"
                      >
                        <font-awesome-icon icon="question-circle" />
                      </span>
                    </label>
                  </template>
                </CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="8">
                <CSelect
                  label="Select"
                  :options="['Option 1', 'Option 2', 'Option 3', 'Option 4']"
                  custom
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="8">
                <div class="form-group">
                  <label>Tipologia</label>
                  <template
                    v-for="option in ['Option 1', 'Option 2', 'Option 3']"
                  >
                    <CInputCheckbox
                      :key="option"
                      :label="option"
                      :value="option"
                      custom
                    />
                  </template>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="8">
                <CInput label="Date" type="date" />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <CCard class="card-bordered">
          <CCardBody>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusamus
            reiciendis harum eum non cumque? Corporis provident dolores, sequi
            animi reiciendis dolor accusamus cupiditate dignissimos rerum
            laboriosam, nobis tenetur doloremque at accusantium ratione maxime
            inventore, culpa impedit deserunt fugit quam commodi facilis id
            quis! A consequatur, eos fuga asperiores labore corrupti repudiandae
            quis porro nesciunt distinctio aliquid sequi. Ea recusandae
            distinctio ad aliquam reiciendis, fuga exercitationem. Maxime, odio
            dolore fugiat optio, rem doloremque ab quo sequi hic aliquam eius.
            Excepturi ducimus fuga porro inventore adipisci minus deleniti eos,
            repellendus nisi officia ab velit laborum quasi perspiciatis at
            repellat saepe laboriosam vero nobis ad. Dicta repellat fugit a
            accusamus iusto ullam ea fugiat atque. Soluta ratione quas
            cupiditate nostrum est? Totam accusamus voluptates aspernatur
            cupiditate? Harum dicta sapiente perspiciatis doloribus beatae
            possimus error earum quasi quia quod iure, labore fugiat placeat
            ducimus ullam laboriosam odio nihil expedita. Molestiae at maxime
            atque voluptatibus inventore autem dignissimos modi in, quod
            reiciendis eius quibusdam. Dolorum vitae quod sint deserunt quia
            assumenda et quis? Adipisci nemo amet voluptatum, doloribus natus
            enim itaque facere hic commodi reiciendis dolorem tempore aperiam
            nam sapiente fugit, repellat accusamus, impedit distinctio quidem
            consectetur sunt? Nesciunt quam earum maxime voluptatem harum quas
            nihil hic numquam ratione, excepturi architecto culpa quis dolor
            possimus aliquid! Incidunt quae minus repudiandae consequuntur
            necessitatibus possimus ipsam assumenda, quis hic delectus adipisci
            aliquid laudantium sint corporis mollitia quia dignissimos, magnam
            totam. Ipsam dolore temporibus ullam. Dolores culpa delectus eos,
            cum voluptatibus ducimus reprehenderit aperiam molestias.
            Consequatur incidunt repudiandae praesentium laboriosam
            reprehenderit voluptates eos, necessitatibus repellat eum ex nemo
            quae aspernatur perspiciatis, maxime quasi voluptate adipisci illo
            quas sunt earum inventore hic? Porro, nam ipsam? Necessitatibus,
            asperiores. Nisi error vero sunt placeat voluptates deserunt tenetur
            sit. Eveniet similique, nobis hic vero corporis neque recusandae
            modi magni nesciunt fugiat impedit ex ipsum, incidunt pariatur
            harum? Rem enim quam, molestias distinctio recusandae, ratione
            dignissimos repellat quia pariatur eius laborum officiis repellendus
            hic ipsum necessitatibus doloremque odit cupiditate magnam deserunt
            ea provident modi. Iure obcaecati quaerat cum inventore in sunt,
            expedita explicabo a magnam porro voluptatum, iste ea unde incidunt
            maxime ducimus totam dolorem dignissimos delectus fugiat aliquid
            ratione nihil dolore neque? Odit vero debitis nobis quis accusamus
            error rem voluptatibus, eius rerum! Reiciendis consectetur iusto,
            quisquam esse aliquid vel quo aut, ab, id deleniti optio numquam
            culpa dolores nostrum. Praesentium ut delectus dolor provident sunt
            dolores ad voluptas asperiores accusamus, molestiae et. Omnis iure
            maiores officia reiciendis iusto eligendi earum asperiores ea et
            deserunt accusamus quo, nihil, porro debitis nemo veniam, optio
            ipsam cupiditate temporibus hic! Minima soluta, quae amet, nemo aut
            officia eveniet aspernatur vero similique ad maiores fugiat, sint
            obcaecati! Ut animi quis odit mollitia sunt beatae error recusandae
            illum, inventore cupiditate earum quas non dolores consequatur
            architecto in a deserunt tempore asperiores ad officiis delectus
            quo! Quidem nihil laborum ad rem. A maiores unde quaerat deserunt
            facilis provident voluptatibus ullam optio in magnam. Impedit,
            voluptatem fugit soluta, nihil, nisi nulla inventore est ad commodi
            enim quam minus et!
          </CCardBody>
        </CCard>
      </main>

      <aside class="ui-product__info">
        <div class="ui-switch">
          Stato prodotto
          <CSwitch variant="3d" size="sm" color="secondary" checked />
        </div>
        <CCard class="card-bordered">
          <CCardBody>
            <section class="mb-4">
              <div class="row justify-content-between">
                <div class="col-auto ui-type-heading">Albicocca</div>
                <div class="col-auto">
                  <CSwitch variant="3d" size="sm" color="secondary" checked />
                </div>
              </div>
              <div>Terranostra</div>
              <div class="row justify-content-between">
                <div class="col-auto">
                  <CBadge shape="pill" color="success">in vendita</CBadge>
                </div>
                <div class="col-auto"><strong>3.50</strong> CHF / pezzo</div>
              </div>
              <hr class="my-2" />
              <div>La bottega del maestro Piff</div>
              <div class="row justify-content-between">
                <div class="col-auto">
                  <CBadge shape="pill" color="info">in approvazione</CBadge>
                </div>
              </div>
              <hr class="my-2" />
              <CButton color="primary" size="sm">VEDI ANTEPRIMA</CButton>
            </section>

            <section>
              <div class="row justify-content-between">
                <div class="col-auto ui-type-heading">Vuota</div>
                <div class="col-auto">
                  <CSwitch variant="3d" size="sm" color="secondary" checked />
                </div>
              </div>
              <div>Terranostra</div>
              <div class="row justify-content-between">
                <div class="col-auto">
                  <CBadge shape="pill" color="success">in vendita</CBadge>
                </div>
                <div class="col-auto"><strong>3.00</strong> CHF / pezzo</div>
              </div>
              <hr class="my-2" />
              <div>La bottega del maestro Piff</div>
              <div class="row justify-content-between">
                <div class="col-auto">
                  <CBadge shape="pill" color="info">in approvazione</CBadge>
                </div>
              </div>
              <hr class="my-2" />
              <CButton color="primary" size="sm">VEDI ANTEPRIMA</CButton>
            </section>
          </CCardBody>
        </CCard>
      </aside>
    </section>
  </div>
</template>

<script>
export default {
  name: "Product",
  data() {
    return {};
  },
};
</script>
